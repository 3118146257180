import Modal from "react-modal";
import styled from "styled-components";
import getSymbolFromCurrency from "currency-symbol-map";
// import { setSelectedPromos } from "../../../shared/slice/promos/PromosSlice";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { BaseURI, store } from "../../../shared";
import React, { useEffect, useState } from "react";
import SelectOption from "../../Menu/productModal/selectOption/SelectOption";
// import SubmitOrder from "../../Menu/productModal/SubmitOrder";
import SubmitOrderPromo from "./SubmitOrderPromo/SubmitOrderPromo";
import "../../Menu/productModal/ProductModal.css";
import { setScroll } from "../../../shared/slice/scroll/ScrollSlice";
import { TextareaAutosize } from "@mui/material";
import { useTranslation } from "react-i18next";

import { setNiveauPromos } from "../../../shared/slice/promos/PromosSlice";
import Stepper from "./Stepper";

export function PromoModal({ selectedPromo, setSelectedPromo }) {
  const { t } = useTranslation();
  const NiveauPromos = useSelector((state) => state.promos.NiveauPromos);
  const [productStep, setProductStep] = useState(0);
  const AcceptedSizes = selectedPromo?.promo.promos[NiveauPromos].AcceptedSizes;
  const dispatch = useDispatch();
  const [aa, setaa] = useState(0);
  useEffect(() => {
    setaa(0);
    setProductStep(NiveauPromos)
  }, [NiveauPromos]);
  const scroll = useSelector((state) => state.scroll.scroll);
  const frais = useSelector((state) => state.restaurant.frais);
  const taux = useSelector((state) => state.restaurant.taux);
  const apply = useSelector((state) => state.restaurant.apply);
  const calculTotal = () => {
    let total = 0;
    for (let i = 0; i < selectedPromo.products.length - 1; i++) {
      total += selectedPromo.products[i]?.product?.price;
    }
    return total;
  };

  /*///////////////////////////////////////////////////// productmodal /////////////////////////////////////////////////////*/
  const [selectedSize, setSelectedSize] = useState({
    size: " ",
    optionGroupe: [],
    subOptionGroup: [],
    subOptionSelected: [],
    price: Number,
    taxe: Number,
  });
  const groupName = selectedSize.optionGroupe;
  const [objectOptions, setObjectOptions] = useState([]);
  const [counter, setCounter] = useState({});
  const [number, setNumber] = useState(0);



  const [checkedIndices, setCheckedIndices] = useState([]);
  const [checkedOption, setCheckedOption] = useState([]);
  const [notes, setNotes] = useState(false);
  const handleChange = (event, product) => {

    const rad = event?.target?.value || event;
    const findSize = product.size.find((grpe) => grpe.name === rad);

    const sub = findSize.optionGroups.flatMap((groupe) => groupe.options);

    const suboption = sub.flatMap((option) => option.subOptionGroup);
    const tax = product.taxes[0].tax.rate;
    setSelectedSize({
      size: rad,
      optionGroupe: findSize.optionGroups,
      subOptionGroup: suboption,
      subOptionSelected: [],
      price: findSize.price,
      priceHt: findSize.price / (1 + tax / 100),
      tax: tax,
    });
    setCounter({});
    setCheckedIndices([]);
    setCheckedOption([]);
    setObjectOptions([]);
  };



  function getOrdinal(n) {
    let ord = "th";

    if (n % 10 == 1 && n % 100 != 11) {
      ord = "st";
    } else if (n % 10 == 2 && n % 100 != 12) {
      ord = "nd";
    } else if (n % 10 == 3 && n % 100 != 13) {
      ord = "rd";
    }

    return ord;
  }

  function removeTrailingS(word) {
    // Check if the word ends with "s"
    if (word.endsWith("s") || word.endsWith("S")) {
      // Remove the last character (which is "s")
      return word.slice(0, -1);
    } else {
      // If the word doesn't end with "s", return it as is
      return word;
    }
  }
  /*///////////////////////////////////////////////////// productmodal /////////////////////////////////////////////////////*/
  const currency = useSelector((state) => state.restaurant?.menu?.currency);
  return (
    <StyledModalPrincipal isOpen={selectedPromo.state}>
      <div
        style={{
          alignSelf: "flex-end",
          cursor: "pointer",
          zIndex: "9999",
        }}
      >
        <button
          className="btn-close button-close"
          style={{}}
          onClick={() => {
            setaa(0);
            setSelectedPromo({
              state: false,
              promo: undefined,
              products: [{ product: undefined, selected: false }],
            });
            store.dispatch(setScroll({ scroll: 0 }));
          }}
        ></button>
      </div>

      {/* <h4 style={{ paddingLeft: "18px" }}>
                {
                    selectedPromo.products[selectedPromo.products.length - 2]?.product
                        .name
                }
            </h4> */}
      {/* back in group of promo */}
      {selectedPromo.products[selectedPromo.products.length - 2]?.selected ===
        false && (
        <div
          style={{
            position: "absolute",
            alignSelf: "flex-start",
            cursor: "pointer",
            zIndex: "9999",
          }}
          onClick={() => {
       
            let data = selectedPromo.products;
            data.pop();
            data[data.length - 1].product = undefined;
            data[data.length - 1].selected = false;
            setSelectedPromo({
              ...selectedPromo,
              products: data,
            });

            // set product to state 1 (l,m,s..)
         
            dispatch(  setNiveauPromos({ NiveauPromos: NiveauPromos-1 }));
            setSelectedSize({});
            setCounter({});
            setCheckedIndices([]);
            setCheckedOption([]);
            setObjectOptions([]);
          }}
        >
          <ArrowBackIosIcon />
        </div>
      )}

      {/* back in product of promo */}

      {/* {selectedPromo.products[selectedPromo.products.length - 2]?.selected ===
                true && (
                    <ArrowBackIosIcon
                        style={{
                            position: "absolute",
                            alignSelf: "flex-start",
                            cursor: "pointer",
                            zIndex: "9999",
                        }}
                        onClick={() => {
                            let data = selectedPromo.products;
                            data.pop();
                            data[data.length - 1].product = undefined;
                            data[data.length - 1].selected = false;
                            setSelectedPromo({
                                ...selectedPromo,
                                products: data,
                            });

                            // set product to state 1 (l,m,s..)

                            setSelectedSize({});
                            setCounter({});
                            setCheckedIndices([]);
                            setCheckedOption([]);
                            setObjectOptions([]);
                        }}
                    />
                )} */}

      {/*/////////////////////////////////////////////////// productModal ///////////////////////////////////////////////////*/}
      {/* confirm product nd map product */}
      {selectedPromo.products[selectedPromo.products.length - 2]?.selected ===
        true && (
        <div>
          {/* map product */}
          {(() => {
            const product =
              selectedPromo.products[selectedPromo.products.length - 2]
                ?.product;
                // setpr(product)
            return (
              <div>
                <ButtonCloseContainer>
                  {/* <div style={{ display: 'flex', alignItems: 'center', fontSize: '1.5rem', fontWeight: 'bold', color: '#333' }}>
                                            {selectedPromo.promo.name}
                                        </div> */}
   
                  <ArrowBackIosIcon
                    style={{
                      marginLeft: "",
                      position: "absolute",
                      alignSelf: "flex-start",
                      cursor: "pointer",
                      zIndex: "9999",
                    }}
                    onClick={() => {
                      setaa(0);
                      let data = selectedPromo.products;
                      data.pop();
                      data[data.length - 1].product = undefined;
                      data[data.length - 1].selected = false;
                      setSelectedPromo({
                        ...selectedPromo,
                        products: data,
                      });

                      // set product to state 1 (l,m,s..)

                      setSelectedSize({});
                      setCounter({});
                      setCheckedIndices([]);
                      setCheckedOption([]);
                      setObjectOptions([]);
                    }}
                  />
                  {}
    
                  <Stepper
  steps={Array.from({ length: selectedPromo.promo.promos.length })}
  activeStep={NiveauPromos}  
  styleConfig={{
    activeBgColor:"#eb1700",
    completedBgColor: "#c21502",
    inactiveBgColor: "#e0e0e0", 
    size: "2em",  
    fontFamily: "Arial, sans-serif",
    circleFontSize: "1em",
    dashColor: "#e0e0e0", // Default dash color
    activeDashColor: "#eb1700", // Dash color before active step
      dashSize: "0.4em"
  }}
/>

                  <h5 style={{ paddingLeft: "18px",textAlign:"center",marginTop:"1rem"}}>{product.name}</h5>
                 
                  {/* <ButtonClose
                                            onClick={() => {
                                                setSelectedPromo({
                                                    state: false,
                                                    promo: undefined,
                                                    products: [{ product: undefined, selected: false }]
                                                });
                                                setSelectedSize({});
                                                store.dispatch(setScroll({ scroll: 0}));
                                            }}
                                        >
                                            <ClearIcon style={{ fontSize: "27px" }} />
                                        </ButtonClose> */}
                </ButtonCloseContainer>
                <ContentContainer groupName={groupName}>
                  <div className="product-popup">
             
                    <div>
                      <div>
                        <div>
                          <img
                            src={`${BaseURI}/combined-uploads/${product.image}`}
                            alt=""
                            className="product-image"
                          ></img>
                          <div>
                            <p>{product.description}</p>
                          </div>
                        </div>
                        <div>
                        <div className="radio-container">
      {product.size.length > 1 && (
        <div className="radio-container">
          {(() => {
            const filteredSizes = product.size.filter(
              (grp) => grp.name !== "standard" && AcceptedSizes.includes(grp.name)
            );

           
            

            // Only render if there are at least 2 valid sizes
            // if (filteredSizes.length >= 2) {
              return (
                <div className="radio-container">
                {filteredSizes.map((grp, index) => (
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic radio toggle button group"
                    key={index} // Unique key for each element
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      value={grp.name}
                      id={`size-${index}`} // Unique ID
                      autoComplete="off"
                      onChange={(event) => handleChange(event, product)} // Handle change for selection
                      checked={selectedSize.size ? selectedSize.size === grp.name : index === 0} // Default to first option
                    />
                    <label
                      className={`btn btn-size ${selectedSize.size === grp.name ? "btnSizeClicked" : ""}`} // Conditional class for selected size
                      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                      htmlFor={`size-${index}`} // Link label to input
                    >
                      <span style={{ fontWeight: "bold", fontSize: "22px" }}>
                        {grp.name}
                      </span>
                      <small>
                        {parseFloat(
                          grp.price + (apply === "product" ? (frais !== null ? frais : grp.price * (taux / 100)) : 0)
                        ).toFixed(2)}{getSymbolFromCurrency(currency)} {/* Display formatted price */}
                      </small>
                    </label>
                  </div>
                ))}
              
                {/* Automatically trigger handleChange with first size during initial render */}
                {!selectedSize.size && handleChange({ target: { value: filteredSizes[0].name } }, product)}
              </div>
              
              );
            // }
            return null; // Fewer than 2 valid sizes
          })()}
        </div>
      )}
    </div>


                        </div>
                      </div>
                     
                      {groupName ? (
                        <div>
                          <SelectOption
                            product={product}
                            selectedSize={selectedSize}
                            setNumber={setNumber}
                            setCounter={setCounter}
                            counter={counter}
                            setCheckedIndices={setCheckedIndices}
                            checkedIndices={checkedIndices}
                            setCheckedOption={setCheckedOption}
                            checkedOption={checkedOption}
                            objectOptions={objectOptions}
                          />

                          {selectedSize.size !== " " ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  marginLeft: "7%",
                                  marginBottom: "2%",
                                }}
                              >
                                <p style={{ fontFamily: "QuicksandBold" }}>
                                  {t("Additional note")} :
                                </p>
                              </div>

                              <TextareaAutosize
                                id="noteTextarea"
                                aria-label=""
                                minRows={3}
                                minCols={20}
                                placeholder={t("leave your additional note")}
                                style={{
                                  width: "90%",
                                  border: "1px solid grey",
                                  borderRadius: "5px",
                                  textAlign: "left",
                                  marginBottom: "50px",
                                  paddingLeft: "5px",
                                  fontFamily: "QuicksandRegular",
                                }}
                                value={selectedSize.note}
                                onChange={(e) =>
                                  setSelectedSize((prevSize) => ({
                                    ...prevSize,
                                    note: e.target.value,
                                  }))
                                }
                              />
                            </>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </ContentContainer>
                {selectedSize.size !== " " && (
                  <div>
                    <Footer>
                      {/* <SubmitOrderPromo
                                                    selectedPromo={selectedPromo}

                                                    setSelectedPromo={setSelectedPromo}
                                                    selectedSize={selectedSize}
                                                    objectOptions={objectOptions}
                                                    counter={counter}
                                                    number={number}
                                                    checkedIndices={checkedIndices}
                                                    checkedOption={checkedOption}
                                                    product={product}
                                                    setNotes={setNotes}
                                                /> */}

                      <SubmitOrderPromo
                        selectedPromo={selectedPromo}
                        setSelectedPromo={setSelectedPromo}
                        selectedSize={selectedSize}
                        objectOptions={objectOptions}
                        counter={counter}
                        number={number}
                        checkedIndices={checkedIndices}
                        checkedOption={checkedOption}
                        product={product}
                        setSelectedSize={setSelectedSize}
                        // setOpenModal={setOpenModal}
                        setCheckedIndices={setCheckedIndices}
                        setCheckedOption={setCheckedOption}
                        setCounter={setCounter}
                        setObjectOptions={setObjectOptions}
                      />
                    </Footer>
                  </div>
                )}
              </div>
            );
          })()}
        </div>
      )}
      {/*/////////////////////////////////////////////////// productModal ///////////////////////////////////////////////////*/}

      {(selectedPromo.products.length === 1 ||
        selectedPromo.products[selectedPromo.products.length - 2]?.selected ===
          false) &&
        selectedPromo.promo.promos.map((selected, index) => (
          
          <div
            key={selected._id}
            style={
              {
                // alignSelf: "center",
                // position: "absolute",
              }
            }
          >
            {/* <CategoryTitle >
                  {selectedPromo.products.length}/{selectedPromo.promo.promos.length}
              </CategoryTitle> */}
            {selected.order === selectedPromo.products.length && (
              <div>
                    <Stepper
  steps={Array.from({ length: selectedPromo.promo.promos.length })}
  activeStep={NiveauPromos}  
  styleConfig={{
    activeBgColor:"#eb1700",
    completedBgColor: "#c21502",
    inactiveBgColor: "#e0e0e0", 
    size: "2em",  
    fontFamily: "Arial, sans-serif",
    circleFontSize: "1em",
    dashColor: "#e0e0e0", // Default dash color
    activeDashColor: "#eb1700", // Dash color before active step
    dashSize: "0.4em"
  }}
/>


                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "1.5rem",
                        marginRight: "5px",
                      }}
                    >
                      {selected.category.name}
                    </div>
                  </div>

                  {/* <img
                                        style={{
                                            height: "6rem",
                                            marginRight: "5px",
                                        }}
                                        src={BaseURI + "/" + selected.category.image}
                                    />


                                    <div style={{
                                        display : "flex",
                                        flexDirection : "column",
                                        justifyContent : "flex-start",
                                        alignItems : "flex-start"
                                    }}>
                                        <div
                                            style={{
                                                fontSize: "1.5rem",
                                                marginRight: "5px",
                                            }}
                                        >
                                            {selected.category.name}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: "14px",
                                                fontFamily: "QuickSandMedium",
                                                color: "var(--primaryColor)",
                                                lineHeight: "1",
                                                textTransform: "lowercase"
                                            }}
                                        > Choose your {selectedPromo.products.length}{getOrdinal(selectedPromo.products.length)}  {removeTrailingS(selected.category.name)}
                                        
                                        </div>
                                    </div> */}
                </div>
              </div>
            )}

            {selected.order === selectedPromo.products.length && <div></div>}

            {selected.order === selectedPromo.products.length && (
              <ScrollableContainer>
               {selected.products
  .filter(product => 
    // Ensure the product has sizes and at least one size matches AcceptedSizes
    product.size?.some(size => AcceptedSizes.includes(size.name))
  )
  .map((product, i) => (

                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      width: "92%",
                      margin: "0px 10px 5px",
                      borderBottom:
                        i !== selected.products.length - 1 &&
                        "1px solid lightgrey",
                    }}
                    onClick={() => {
                      const updatedProduct = {
                        ...product,
                        size: product.size.map((size) => ({
                          ...size,
                          optionGroups: [
                            ...product.optionGroups,
                            ...size.optionGroups,
                          ],
                        })),
                      };
                      if (updatedProduct.size.length === 1) {
                        handleChange(
                          updatedProduct.size[0]?.name,
                          updatedProduct
                        );
                      } else {
                        setSelectedSize({});
                        setCounter({});
                        setCheckedIndices([]);
                        setCheckedOption([]);
                        setObjectOptions([]);
                      }
                      let data = selectedPromo.products;
                      data[data.length - 1].product = updatedProduct;
                      data[data.length - 1].selected = true;
                      data.push({ product: undefined, selected: false });

                      setSelectedPromo({
                        ...selectedPromo,
                        products: data,
                      });
                    }}
                  >
                    <img
                      style={{
                        height: "auto",
                        // marginRight: "5px",
                        width: "15%",
                        maxWidth: "fit-content",
                        marginRight: "8px",
                      }}
                      src={BaseURI + "/" + product.image}
                    />

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "85%",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "left",
                          width: "80%",
                          margin: "10px 0px 10px 0px",
                        }}
                      >
                        <div>{product.name}</div>
                        <div
                          style={{
                            fontSize: "14px",
                            fontFamily: "QuickSandMedium",
                            color: "grey",
                            lineHeight: "1",
                          }}
                        >
                          {product.description.length > 74
                            ? product.description.substring(0, 74) + "..."
                            : product.description}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </ScrollableContainer>
            )}
          </div>
        ))}
      {/* {(selectedPromo.products.length === 1 || selectedPromo.products[selectedPromo.products.length - 2]?.selected === false) && <div style={{
                alignSelf: "center",
                marginTop: "auto"
            }}>Total price : {calculTotal()}{getSymbolFromCurrency(currency)}</div>} */}
    </StyledModalPrincipal>
  );
}

function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal className={modalClassName} portalClassName={className} {...props} />
  );
}
const StyledModalPrincipal = styled(ReactModalAdapter).attrs({
  modalClassName: "Modal",
  overlayClassName: "Overlay",
})`
  .Modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 15px 8px 2px;
    border-radius: 15px;
    width: 500px;
    min-width: 400px;
    max-height: 99%;
    min-height: 50%;
    text-align: center;
    outline: none;
    /* overflow: hidden; */
    overflow-y: hidden;

    @media (max-width: 768px) {
      overflow-x: hidden;
      min-width: 200px;
      width: 95%;
      //   height: 100%;
      max-height: 100%;
      border-radius: 15px;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  .Overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
`;
const CategoryTitle = styled.div`
  align-self: flex-start;
  background-color: var(--primaryColor);
  border-radius: 100%;
  padding: 2px;
  font-size: 12px;
  font-weight: bold;
  margin-right: 20%;
  color: #fff;
  padding: 6px;
  @media (max-width: 768px) {
    margin-right: 1%;
  }
`;

const ButtonCloseContainer = styled.div`
  width: 100%;
  margin-top: 5px;
  //   height: 27px;
  background-color: #fff;
  position: sticky;
  top: 0px;
  text-align: left;
  border-bottom: 1px solid #33333347;
  //   height: 35px;
  z-index: 1000;
`;
const ScrollableContainer = styled.div`
  margin-top: 1rem;
  overflow-y: auto;
  height: 700px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
const ButtonClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 10px;
  cursor: pointer;
  background-color: white;
  margin-top: 2px;
`;
// const ModalContainer = styled.div`
//   position: relative;
// `;

const Footer = styled.div`
  width: 100%;
  padding: 6px;
  border-top: 1px solid #33333347;
  background: #fff;
  @media (max-width: 768px) {
    position: absolute;
    bottom: 0;
  }
`;
// const Footer = styled.div`
// position: sticky;
// bottom: 0;
// width: 100%;
// padding: 8px;
// background-color: #fff;
// `;
const ContentContainer = styled.div`
  overflow-y: scroll;
  //    height: 80%;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  height: ${(props) => {
    switch (true) {
      case props.groupName?.length > 0:
        return "90%";
      default:
        return "50%";
    }
  }};
`;
