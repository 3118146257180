import { combineReducers, createStore } from "@reduxjs/toolkit";

import { persistStore, persistReducer } from "redux-persist";
import { rootSlice } from "./slice/rootSlice";

import storage from "redux-persist/lib/storage"; // Import storage from redux-persist/lib
// import { authentificationSlice } from "./slice/auth/AuthSlice";
import { companySlice } from "./slice/company/CompanySlice";
import { restaurantSlice } from "./slice/restaurant/RestaurantSlice";
import { modalLoginSlice } from "./slice/ModalLogin/ModalLoginSlice";
import { authentificationSlice } from "./slice/auth/AuthSlice";
import { orderSlice } from "./slice/order/OrderSlice";
import { promosSlice } from "./slice/promos/PromosSlice";
import { scrollSlice } from "./slice/scroll/ScrollSlice";
import { modalModeSlice } from "./slice/ModalMode/ModalMode";
import { modalStoresSlice } from "./slice/ModalStores/ModalStores";
import { ModalNotificationSlice } from "./slice/ModalNotification/ModalNotification";

// export const ClientURI = "https://backendv1-0.onrender.com/client"
// export const SseURI = "https://backendv1-0.onrender.com/sse"
// export const BaseURI = "https://backendv1-0.onrender.com"

    //   export const ClientURI="http://localhost:8000/client"
    // export const SseURI = "http://localhost:8000/sse"
    // export const BaseURI="http://localhost:8000"

  //  export const ClientURI="http://192.168.1.49:8000/client"

  //  export const SseURI = "http://192.168.1.49:8000/sse"
  //  export const BaseURI="http://192.168.1.49:8000"

    // export const ClientURI="https://market.eatorder.fr/api"
    // export const SseURI = "https://market.eatorder.fr/api/sse"
    // export const BaseURI="https://market.eatorder.fr/api"

      export const ClientURI="https://server.eatorder.fr:8000/client"
      export const SseURI = "https://server.eatorder.fr:8000/sse"
      export const BaseURI="https://server.eatorder.fr:8000"
    
      // export const ClientURI="https://127.0.0.1:8000/client"
      // export const SseURI = "https://127.0.0.1:8000/sse"
      // export const BaseURI="https://127.0.0.1:8000"

    // export const ClientURI="https://market.eatorder.fr/api/client"
    // export const SseURI = "https://market.eatorder.fr/api/sse"
    // export const BaseURI="https://market.eatorder.fr/api"
// https://api.eatorder.fr


const persistConfig = {
  key: "root",
  storage: storage,
  // whitelist: ["root", 'company', "authentification", "restaurant", "order", "promos","modalMode"],
  whitelist: ["root", 'company', "authentification", "restaurant", "order", "modalMode"],
};

const rootReducer = combineReducers({
  root: rootSlice.reducer,
  authentification: authentificationSlice.reducer,
  company: companySlice.reducer,
  restaurant: restaurantSlice.reducer,
  modalLogin: modalLoginSlice.reducer,
  order: orderSlice.reducer,
  promos: promosSlice.reducer,
  scroll: scrollSlice.reducer,
  modalMode: modalModeSlice.reducer,
  modalStores : modalStoresSlice.reducer,
  ModalNotification: ModalNotificationSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer);

export const persistor = persistStore(store);
