import { BaseURI } from "../..";
import { Executor } from "../../Executor";

export const getstorebyidcompany = (id) => {
    return Executor({
        method: 'get',
        url: BaseURI + `/client/storesByCompany/${id}`,
        isSilent: true,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}

export const getStoreById = (_id) => {
    return Executor({
        method: 'get',
        url: BaseURI + `/client/store/${_id}`,
        isSilent: true,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}

export const getMenuByStore = (store) => {
    return Executor({
        method: 'get',
        url: BaseURI + `/client/getMenuByStore/${store}`,
        isSilent: true,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const getProductByStoreByMode = (store, mode) => {
    return Executor({
        method: 'get',
        url: BaseURI + `/client/products-by-store/${store}/${mode}`,
        isSilent: false,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const getItemProductByStoreByMode = (store, mode) => {
    return Executor({
        method: 'get',
        url: BaseURI + `/client/productId/${store}/${mode}`,
        isSilent: false,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}
// export const getStoreID = (storeName) => {
//     return Executor({
//         method: 'get',
//         url: BaseURI + `/client/GetstorewithNameAndLast4Caracter/${storeName}`,
//         isSilent: true,
//         successFun: (data) => {
//         },
//         withErrorToast: false,
//         withSuccessToast: false,
//     });
// }
export const getStoreID = (storeSlug) => {
    return Executor({
        method: 'get',
        url: BaseURI + `/client/GetstorewithSlug/${storeSlug}`,
        isSilent: true,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const getModeConsomation = (store) => {
    return Executor({
        method: 'get',
        url: BaseURI + `/client/modeConsomation/${store}`,
        isSilent: true,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const getMode = (id) => {
    return Executor({
        method: 'get',
        url: BaseURI + `/client/modeById/${id}`,
        isSilent: true,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}


export const getAllStores = () => {
    return Executor({
        method: 'get',
        url: BaseURI + `/client/stores`,
        isSilent: false,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const getTopStores = () => {
    return Executor({
        method: 'get',
        url: BaseURI + `/client/top-stores-Selling`,
        isSilent: true,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const getTopProduct = () => {
    return Executor({
        method: 'get',
        url: BaseURI + `/client/top-products-Selling`,
        isSilent: true,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}


export const getAllspecialites = () => {
    return Executor({
        method: 'get',
        url: BaseURI + `/client/specialites`,
        isSilent: true,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const getStoresByspecialites = (id) => {
    return Executor({
        method: 'get',
        url: BaseURI + `/client/stores-specialites/${id}`,
        isSilent: true,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const getAllStoresActive = (id) => {
    return Executor({
        method: 'get',
        url: BaseURI + `/client/getAllStoresActive`,
        isSilent: true,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}